import {
  Box,
  FormControl,
  Input,
  Button,
  InputLeftElement,
  Icon,
  InputGroup,
  Text,
  Stack,
} from "@chakra-ui/core"
import React, { useState } from "react"
import { useToast } from "@chakra-ui/core";

const FormEmail = (props) => {
  const {group} = props;
  const [email, setEmail] = useState("")
  const toast = useToast();
  const handleSubmit = async e => {
    e.preventDefault()
    const url = "/.netlify/functions/ml-add";
    const settings = {
      method: "POST",
      body: JSON.stringify({ email ,group}),
    };
    const response = await fetch(url, settings);
    const body = await response.text();
    console.log(response);
    toast({
      title: response.status === 200 ? "Success" : "Error",
      description: body,
      status: response.status === 200 ? "success" : "error",
      duration: 9000,
      isClosable: true,
    })
    setEmail("");
    if (response.status === 200 && typeof window !== 'undefined') {
      window.location = '/email-sent'
    }
  }

  return (
<Box p={8} px={6}>
      {props.children}
      <form onSubmit={handleSubmit}>
        <FormControl isRequired pt={2}>
          <InputGroup size="lg">
            <InputLeftElement
              children={<Icon name="email" color="gray.300" />}
            />
            <Input
              type="email"
              id="email"
              placeholder="Enter Your Email Address"
              aria-describedby="email-helper-text"
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </InputGroup>
        </FormControl>
        <Button
          width="100%"
          mt={4}
          variantColor="green"
          type="submit"
          fontWeight="bold"
          py={8}
          fontSize="2xl"
        >
          Get Instant Access
        </Button>
        <Stack isInline py={6} align="center" justify="center">
          <Icon name="lock" />
          <Text fontSize="sm">
            This service is completely free. A credit card is NOT required.
          </Text>
        </Stack>
      </form>
    </Box>
  )
}
export default FormEmail
